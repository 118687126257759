import React from 'react'
import { BiDonateBlood, BiGlobeAlt, BiAtom, BiBrain } from "react-icons/bi"



function Info() {
  return (
    <div className="flex w-100 justify-items-stretch" style={{ background: 'rgba(0, 0, 0, 0.7)' }}>
      <div className="w-4 center p-5" style={{ background: 'rgba(47, 48, 88, 0.5)' }}>
        <div className="pt-4 px-3"><BiAtom color={'#fff'} size={40} opacity={'.5'} weight={100} /></div>
        <h3 className="subtitle c-theme mt-3 mx-4"><strong>Huge variety of agricultural projects</strong></h3>
        <p className="text c-theme px-3 mx-4">Hundreds of unique farming opportunities and innovative business models are available on AI Cloud, so you are sure to find something that fits both your needs and your interests.</p>
      </div>
      <div className="w-4 center p-5 bg-blue" style={{ background: 'rgba(56, 47, 104, 0.6)' }}>
        <div className="pt-4 px-3"><BiDonateBlood color={'#fff'} size={40} opacity={'.5'} weight={100} /></div>
        <h3 className="subtitle c-theme mt-3 mx-4"><strong>Investing in sustainable farming practices</strong></h3>
        <p className="text c-theme px-3 mx-4">We understand how important it is to protect the environment and leave behind a greener world for generations to come. That's why we only partner with farmers who use sustainable practices that don't harm the land.</p>
      </div>
      <div className="w-4 center p-5 " style={{ background: 'rgba(65, 47, 88, 0.5)' }}>
        <div className="pt-4 px-3"><BiBrain color={'#fff'} size={40} opacity={'.5'} weight={100} /></div>
        <h3 className="subtitle c-theme mt-3 mx-4"><strong>Smart technology, human touch</strong></h3>
        <p className="text c-theme px-3 mx-4">We use a state-of-the-art AI-powered algorithm that analyses data on climate, soil and weather to help you choose the right crop and livestock for your farm - no matter where in the world you are.</p>
      </div>
      <div className="w-4 center p-5" style={{ background: 'rgba(84, 40, 85, 0.6)' }}>
        <div className="pt-4 px-3"><BiGlobeAlt color={'#fff'} size={40} opacity={'.5'} weight={100} /></div>
        <h3 className="subtitle c-theme mt-3 mx-4"><strong>Accessible from anywhere in the world</strong></h3>
        <p className="text c-theme px-3 mx-4">No matter where you are in the world, you'll be able to access AI Cloud and invest in the future of agriculture.</p>
      </div>

    </div>
  )
}

export default Info
