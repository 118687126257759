import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


import { TfiStar } from 'react-icons/tfi'


import Title from './Title'
import pic1 from '../assets/img/services/log001.jpg'
import pic2 from '../assets/img/services/log002.jpg'
import pic3 from '../assets/img/services/log003.jpg'
import pic4 from '../assets/img/services/log004.jpg'
import pic5 from '../assets/img/services/log005.jpg'
import pic6 from '../assets/img/services/log006.jpg'
import pic7 from '../assets/img/services/log007.jpg'
import pic8 from '../assets/img/services/log008.jpg'
import pic9 from '../assets/img/services/log009.jpg'
import pic10 from '../assets/img/services/log010.jpg'
import pic11 from '../assets/img/services/log011.jpg'
import pic12 from '../assets/img/services/log012.jpg'


function Cases() {

  const slides = [
    {
      id: 1,
      pic: pic1,
      text: `AI Cloud provides access to a vast network of farmers from around the world, giving investors the ability to diversify their portfolios.`
    },
    {
      id: 2,
      pic: pic2,
      text: `AI Cloud offers investors access to the latest market trends, enabling them to make informed decisions.`
    },
    {
      id: 3,
      pic: pic3,
      text: `Investors can benefit from the growing demand for agro-products and services globally.`
    },
    {
      id: 4,
      pic: pic4,
      text: `AI Cloud’s innovative technology solutions help farmers to increase yields, reduce costs and improve efficiency.`
    },
    {
      id: 5,
      pic: pic5,
      text: `AI Cloud’s comprehensive data analysis and insights allow investors to gain valuable insights into the global agriculture market.`
    },
    {
      id: 6,
      pic: pic6,
      text: `AI Cloud’s platform facilitates investment in sustainable farming practices, enabling investors to benefit from long-term returns.`
    },
    {
      id: 7,
      pic: pic7,
      text: `AI Cloud offers investors access to a wide range of agricultural projects and investments, enabling them to diversify their portfolios.`
    },
    {
      id: 8,
      pic: pic8,
      text: `AI Cloud provides investors with access to unique farming opportunities and innovative business models.`
    },
    {
      id: 9,
      pic: pic9,
      text: `AI Cloud’s global network of farmers provides investors with the opportunity to build long-term relationships.`
    },
    {
      id: 10,
      pic: pic10,
      text: `AI Cloud’s platform enables investors to track their investments and receive automated alerts in real-time.`
    },
    {
      id: 11,
      pic: pic11,
      text: `AI Cloud is an online platform that connects investors giving everyone access to fresh and healthy food, anywhere it needs to be.`
    },
    {
      id: 12,
      pic: pic12,
      text: `AI Cloud groundbreaking technology has already attracted more than 30 million users in over 160 countries.`
    }
  ]

  const Gallery = () => {
    var settings = {
      autoplay: true,
      dots: false,
      arrows: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className='mx-5'>
        <Slider {...settings}>
          {slides.map(screen => (
            <div className="w-30 my-5 oh">
              <div className='mx-2 r-8 shadow-sm bg-dark'>
                <img
                  className='w-100 h-30 r-8 obf'
                  src={screen.pic}
                  alt={'pic'}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <div className="section" id="cases_block">
      <Title
        type="title c-blue"
        text="Our Services"
      />
      <div className="container">
        <h2 className="subtitle w-70 ma text-center c-theme">We have a wide array of skilled developers on our team, ready to work for you. From new teams needing help with small projects to large-scale enterprises that need full-time staff, we have the plan for you.
        </h2>
        <div className="s-40"></div>
        <Gallery />
        <div className="s-80"></div>
        <div className="flex rowrap w-100">
          {slides.map(screen => (
            <div className="w-30 my-5 oh ma">
              <div className='mx-2 r-8 shadow-sm box-card'>
                <div className="p-5">
                  <TfiStar size={24} style={{ marginLeft: 8 }} />
                  <h3 className="text text-left">
                    <div className="pl-3 pr-2 c-white">{screen.text}</div></h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Cases
