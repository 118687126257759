import React from 'react'

import AnchorLink from 'react-anchor-link-smooth-scroll'


function Cta() {
  return (
    <div className="section" id="cta_block">
      <div className="flex aic jcc">
        <div className="text-center h-100">
          <p className="c-blue title w-60 ma" style={{ fontSize: 60, fontWeight: 600, textTransform: 'none' }}>Invest in a better world!</p>
          <div className="s-20"></div>
          <p className="text text-center c-white w-70 ma">All your marketing data, from website analytics to CRM and marketing automation, in one place. Our solution provides you with a holistic view of your customers for better targeting and customer experience. Get ready for more qualified leads, less churn, and a high ROI. You've seen how our competitor handles business intelligence? Well, we're the complete opposite. We take all the features they have, and we make them 10x better while still being affordable.</p>
          <div className="s-20"></div>
          <AnchorLink href="#contact_block" className='btn btn--big btn--premium bg-white btn--big'>Start Now</AnchorLink>
        </div>
      </div>
    </div>
  );
}

export default Cta
