import React from 'react'
import video from '../assets/media/video-bg.mp4'

export default function Intro() {

  return (
    <div className="intro">
      <video autoPlay loop muted style={{
        position: 'fixed',
        zIndex: -1,
        width: '100%'
      }}>
        <source src={video} type='video/mp4' />
      </video>
      <main>
        <div className="flex jcc aic h-100">
          <div className="w-80 ma">
            <div>
              <p className="intro__subtitle text-center c-blue">Smart technology - world of opportunities

              </p>
              <h1 className="intro__title font-bold c-white">
                Invest in a better world
              </h1>
              <p className="subtitle c-premium text-center w-50 ma">
                Invest in projects that are near or far away from where you live - all while investing in areas that are important to you
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
