import React from 'react'

import { RiGlobalLine, RiContrastDropLine, RiScan2Line } from "react-icons/ri"

import AnchorLink from 'react-anchor-link-smooth-scroll'
import Title from './Title'


function Features() {
  return (
    <div className="section" id="feature_block">
      <Title
        type="title c-blue"
        text="We offer you a global network of farmers"
      />
      <div className="container">
        <h2 className="subtitle w-70 ma text-center c-white">Countries all over the world seek to improve the food supply chains and agricultural infrastructure to meet their needs. For those looking for a new way to invest, investing in agriculture provides investors with the opportunity to contribute to this goal.
        </h2>
        <div className="s-40"></div><div className="s-40"></div>
        <div className="flex jcsa w-90 ma">
          <div className="w-30 text-center">
            <span className="a-icon large br-lite br-two r-16 mb-5">
              <RiGlobalLine size={40} color={'white'} />
            </span>
            <h3 className="subtitle">Access to investments from anywhere</h3>
            <p className="text c-white">We provide investors with access to a range of agricultural projects that are easy to invest in, at a reasonable price. Investing in agriculture has never been easier!</p>
          </div>
          <div className="w-30 text-center">
            <span className="a-icon large br-lite br-two r-16 mb-5">
              <RiContrastDropLine size={40} color={'white'} />
            </span>
            <h3 className="subtitle">Unique farming opportunities</h3>
            <p className="text c-white">
              AI Cloud offers access to a vast network of farmers from anywhere, at anytime. This unique advantage offers investors with access to innovative business models, as well as provides them with an opportunity to diversify their portfolios.</p>
          </div>
          <div className="w-30 text-center">
            <span className="a-icon large br-lite br-two r-16 mb-5">
              <RiScan2Line size={48} color={'white'} />
            </span>
            <h3 className="subtitle">No geographical constraints</h3>
            <p className="text c-white">
              With AI Cloud's global network of farmers, you can easily invest in projects that are near or far away from where you live - all while investing in areas that are important to you.</p>
          </div>
        </div>
        <div className="s-80"></div>
        <div className="text-center">
          <AnchorLink href="#contact_block" className='btn btn--big btn--premium'>Connect to AI Cloud</AnchorLink>
        </div>
      </div>
    </div>
  )
}

export default Features
