import React from 'react'

import dashboard from '../assets/img/content/admin.png'

function About() {
  return (
    <div className="section" id="about_block">

      <div className="w-80 ma">
        <h2 className='title c-blue'>Invest today and provide a better future for tomorrow!</h2>
      </div>
      <div className="s-40"></div>
      <div className="s-40"></div>
      <div className="container">
        <div className="flex w-90 ma jcsa aic">
          <div className="w-40">
            <p className="text c-middle text-right">AI Cloud is an online platform that connects investors to a global network of farmers, giving everyone access to fresh and healthy food, anywhere it needs to be. With a strong focus on transparency, we're providing the financial and logistical infrastructure for producers to succeed.</p>
            <p className="text c-middle text-right">The platform creates the opportunity for investors to invest in the agricultural sector and a network of farms that are looking to grow yields! We're revolutionizing the agricultural industry with a global marketplace that connects investors with farmers.</p>
          </div>
          <div className="w-50 flex jcc aic p-5">
            <img src={dashboard} alt="pic" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
